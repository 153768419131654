@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Black.eot');
    src: url('/fonts/Graphik-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-Black.woff2') format('woff2'),
        url('/fonts/Graphik-Black.woff') format('woff'),
        url('/fonts/Graphik-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Bold.eot');
    src: url('/fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-Bold.woff2') format('woff2'),
        url('/fonts/Graphik-Bold.woff') format('woff'),
        url('/fonts/Graphik-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Extralight.eot');
    src: url('/fonts/Graphik-Extralight.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-Extralight.woff2') format('woff2'),
        url('/fonts/Graphik-Extralight.woff') format('woff'),
        url('/fonts/Graphik-Extralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-BlackItalic.eot');
    src: url('/fonts/Graphik-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-BlackItalic.woff2') format('woff2'),
        url('/fonts/Graphik-BlackItalic.woff') format('woff'),
        url('/fonts/Graphik-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-BoldItalic.eot');
    src: url('/fonts/Graphik-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-BoldItalic.woff2') format('woff2'),
        url('/fonts/Graphik-BoldItalic.woff') format('woff'),
        url('/fonts/Graphik-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-ExtralightItalic.eot');
    src: url('/fonts/Graphik-ExtralightItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-ExtralightItalic.woff2') format('woff2'),
        url('/fonts/Graphik-ExtralightItalic.woff') format('woff'),
        url('/fonts/Graphik-ExtralightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-LightItalic.eot');
    src: url('/fonts/Graphik-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-LightItalic.woff2') format('woff2'),
        url('/fonts/Graphik-LightItalic.woff') format('woff'),
        url('/fonts/Graphik-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Medium.eot');
    src: url('/fonts/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-Medium.woff2') format('woff2'),
        url('/fonts/Graphik-Medium.woff') format('woff'),
        url('/fonts/Graphik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Light.eot');
    src: url('/fonts/Graphik-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-Light.woff2') format('woff2'),
        url('/fonts/Graphik-Light.woff') format('woff'),
        url('/fonts/Graphik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-MediumItalic.eot');
    src: url('/fonts/Graphik-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-MediumItalic.woff2') format('woff2'),
        url('/fonts/Graphik-MediumItalic.woff') format('woff'),
        url('/fonts/Graphik-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Regular.eot');
    src: url('/fonts/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-Regular.woff2') format('woff2'),
        url('/fonts/Graphik-Regular.woff') format('woff'),
        url('/fonts/Graphik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-RegularItalic.eot');
    src: url('/fonts/Graphik-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-RegularItalic.woff2') format('woff2'),
        url('/fonts/Graphik-RegularItalic.woff') format('woff'),
        url('/fonts/Graphik-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik Super';
    src: url('/fonts/Graphik-Super.eot');
    src: url('/fonts/Graphik-Super.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-Super.woff2') format('woff2'),
        url('/fonts/Graphik-Super.woff') format('woff'),
        url('/fonts/Graphik-Super.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-SemiboldItalic.eot');
    src: url('/fonts/Graphik-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-SemiboldItalic.woff2') format('woff2'),
        url('/fonts/Graphik-SemiboldItalic.woff') format('woff'),
        url('/fonts/Graphik-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Thin.eot');
    src: url('/fonts/Graphik-Thin.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-Thin.woff2') format('woff2'),
        url('/fonts/Graphik-Thin.woff') format('woff'),
        url('/fonts/Graphik-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik Super';
    src: url('/fonts/Graphik-SuperItalic.eot');
    src: url('/fonts/Graphik-SuperItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-SuperItalic.woff2') format('woff2'),
        url('/fonts/Graphik-SuperItalic.woff') format('woff'),
        url('/fonts/Graphik-SuperItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-ThinItalic.eot');
    src: url('/fonts/Graphik-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-ThinItalic.woff2') format('woff2'),
        url('/fonts/Graphik-ThinItalic.woff') format('woff'),
        url('/fonts/Graphik-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Semibold.eot');
    src: url('/fonts/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Graphik-Semibold.woff2') format('woff2'),
        url('/fonts/Graphik-Semibold.woff') format('woff'),
        url('/fonts/Graphik-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}