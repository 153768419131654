@font-face {
    font-family: 'Sailec';
    src: url('/fonts/Sailec-Bold.woff2') format('woff2'),
        url('/fonts/Sailec-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('/fonts/Sailec-BoldItalic.woff2') format('woff2'),
        url('/fonts/Sailec-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('/fonts//font/Sailec-Black.woff2') format('woff2'),
        url('/fonts/Sailec-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('/fonts/Sailec-Medium.woff2') format('woff2'),
        url('/fonts/Sailec-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('/fonts/Sailec-MediumItalic.woff2') format('woff2'),
        url('/fonts/Sailec-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('/fonts/Sailec-Italic.woff2') format('woff2'),
        url('/fonts/Sailec-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('/fonts/Sailec.woff2') format('woff2'),
        url('/fonts/Sailec.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}