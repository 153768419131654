/* @font-face {
  font-family: 'Graphik';
  src: url('/public/fonts/Graphik-Regular.woff2') format('woff2'), url('/public/fonts/Graphik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-light';
  src: url('/public/fonts/Graphik-Light.woff2') format('woff2'), url('/public/fonts/Graphik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-bold';
  src: url('/public/fonts/Graphik-Semibold.woff2') format('woff2'), url('/public/fonts/Graphik-Semibold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
} */
/* 
@font-face {
  font-family: 'SFPro-Regular';
  src: url('/public/fonts/SFPro-Regular.woff') format('woff'),
    url('/public/fonts/SFPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFPromd';
  src: url('/public/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
    url('/public/fonts/SFProDisplay-Semibold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Integral CF';
  src: url('/public/fonts/IntegralCF-Bold.woff2') format('woff2'),
    url('/public/fonts/IntegralCF-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Integral CF';
  src: url('/public/fonts/IntegralCF-Regular.woff2') format('woff2'),
    url('/public/fonts/IntegralCF-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'SFPro';
  src: url('/public/fonts/SFProDisplay-Heavy.woff2') format('woff2'),
    url('/public/fonts/SFProDisplay-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
} */

:root {
  --common-font: 'SFPro-Regular';
  --common-fontbd: 'SFPro';
  --common-fontmd: 'SFPromd';
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--common-font);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--common-font);
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.consent_form_main_dialog {
  background: #56b4bde3;
}

h3.consent-form {
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  font-size: 46px;
}

p.consent-para {
  font-size: 22px;
  color: #fff;
  text-align: center;
}

button.btn-primary-btn-consent {
  border-radius: 21px;
  width: 375px;
  font-size: 20px;
  color: #000;
  padding-right: 27px;
  margin: 48px;
}

.review_section_product_page1 {
  width: 100%;
  height: 500px;
}

.review_section_product_page1 .review_section_product_page1_main_dialog {
  height: 476px !important;
}

.review_section_product_page1 .review_section_product_page1_main_dialog .MuiDialogContent-root.container_for_load_data {
  height: calc(100% - 80px);
}

.review_section_product_page1 .review_section_product_page1_main_dialog .MuiDialogContent-root.container_for_load_data .ActiveStyleComp__DialogSectionCss-sc-rlj7ni-1 {
  height: 100%;
}

.review_section_product_page1 {
  border-bottom: 30px solid #5cb5be;
}

.review_section_product_page1 .review_section_product_page1_main_dialog .MuiDialogContent-root.container_for_load_data .upsell-popup {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.review_section_product_page1 .review_section_product_page1_main_dialog button.close_button {
  display: none;
}

.DialogComponent__DialogStyle-sc-1hw19y8-0.gwABjb {
  overflow: hidden;
}

.track-order.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 100%;
  max-width: 400px;
  height: 24%;
}

.track-order-dna {
  background: var(--green);
  color: #fff;
  border-radius: 12px;

}

.track-order-dna.popup-display-button {
  background: var(--green);
  color: #fff;
  border-radius: 12px;
  padding: 40px 80px 40px 80px;
  font-size: 20PX;
}

.track-order-dna.popup-display-button.data-dna-new {
  background: var(--green);
  color: #fff;
  border-radius: 12px;
  padding: 40px 80px 40px 80px;
  font-size: 20px;
}

* {
  box-sizing: border-box;
}

/* 28 sep 2023 */

.cstm-popup .MuiDialog-container .MuiPaper-root {
  width: 100%;
}

/* 23 april 2024 starts */
.new_checkout_page .checkout_main_section_class {
  padding: 0;
}

.new_checkout_page div#__next {
  background: transparent;
}

/* 23 april 2024 ends */

/* 25 april 2024 starts */
.new_checkout_page .main_div .checkout_main_section_class {
  padding: 0;
}

.reports-supplement-dna .note-supplements {
  font-size: 14px;
  color: #000;
  line-height: 1.5;
}

.reports-supplement-dna {
  margin-top: 30px;
}

/* 25 april 2024 ends */